import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["review"]

  filterReviews(rating) {
    this.reviewTargets.forEach(review => {
      const reviewRating = review.dataset.rating
      if (rating === 'all' || reviewRating === rating) {
        review.style.display = 'block'
      } else {
        review.style.display = 'none'
      }
    })
  }

  connect() {
    const ratingFilters = document.querySelectorAll('.rating-filter')
    ratingFilters.forEach(filter => {
      filter.addEventListener('click', () => {
        const rating = filter.dataset.rating
        this.filterReviews(rating)
      })
    })
  }
}