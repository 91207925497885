import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "ratingSelect", "verifiedSelect", "industrySelect"]

  connect() {
    this.formTarget.addEventListener("change", this.handleFilterChange.bind(this))
  }

  handleFilterChange() {
    // const rating = this.ratingSelectTarget.value
    const isVerified = this.verifiedSelectTarget.value
    const industry = this.industrySelectTarget.value

    const url = new URL(window.location.href)
    // url.searchParams.set("average_rating", rating)
    url.searchParams.set("is_verified", isVerified)
    url.searchParams.set("industry", industry)

    window.location.href = url.toString()
  }
}
