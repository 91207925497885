// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import Autocomplete from "stimulus-autocomplete"
application.register("autocomplete", Autocomplete)

import Filter from './filter_controller'
application.register("filter", Filter)

import FilterByRatings from './filter_by_ratings_controller'
application.register("filter-by-ratings", FilterByRatings)

import Lightbox from 'stimulus-lightbox'
application.register('lightbox', Lightbox)

import TextareaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextareaAutogrow)

import DarkToggle from './dark_toggle_controller'
application.register('dark-toggle', DarkToggle)

// todo: remove this console.log
console.log('Hello from the Buyeketa Team!')
